import { createApp } from 'vue';

import main from "main.vue";
import router from "config/router";

import cookieComponent from "cookieconsent.vue";

import config from "config";

// Set our app
const app = createApp(main);
app.use(router);
app.mount('#app');


// Set our app
const cookieTarget = createApp(cookieComponent);
cookieTarget.mount('#cookieComponent');