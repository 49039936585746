<template>
    <router-view />
</template>

<script>

import {computed, defineComponent, provide, reactive} from "vue";

// Our styles
import styles from 'css/app.scss';

// import { getUrlWithQueryItemReplaced } from "helpers/_structure.mjs";

export default defineComponent({
    name: "app",
    setup() {
        const generalData = reactive({
            finished: false,
            summary: false,
            succesful: false,
            showTemplate: false,
            lists: {
                list_id: 13
            }
        });

        const componentData = reactive({
            total: 0,
            current_question: 0,
            values: {},
            loading_kadaster: false,
            kadaster: null,
            questions: [
                {
                    "title": "Het projectadres",
                    "category": "address",
                    "content": "Vul uw postcode en huisnummer in voor een aanbod op maat.",
                    "multiple": false,
                    "hasTextInput": true,
                    "answers": [
                        {
                            "name" : "postcode",
                            "title": "Postcode:",
                            "inputLabel": "Postcode *",
                            "width": "span4"
                        },
                        {
                            "name" : "huisnummer",
                            "title": "Huisnummer:",
                            "inputLabel": "Huisnummer *",
                            "width": "span4"
                        },
                        {
                            "name" : "toevoeging",
                            "title": "Toevoeging:",
                            "inputLabel": "Toevoeging:",
                            "width": "span4"
                        }
                    ]
                },
                {
                    "title": "Wat wilt u aanvragen?",
                    "category": "interests",
                    "content": "U kunt meerdere diensten selecteren.",
                    "multiple": true,
                    "answers": [
                        {
                            "name"      : "Energielabel",
                            "title"     : "Energielabel",
                            "content"   : "Wij nemen uw pand op en registreren een definitief energielabel. Vaak ten behoeven van de verkoop.",
                            "icon"      : "energielabel"
                        },
                        {
                            "name"      : "Bouwkundige keuring",
                            "title"     : "Bouwkundige keuring",
                            "content"   : "Wij inspecteren het gehele pand op de bouwkundige staat. Vaak ten behoeve van de aankoop.",
                            "icon"      : "bouwkundige_keuring"
                        }
                    ]
                },
                {
                    "title": "Ook interessant voor u",
                    "category": "services",
                    "content": "Breid uw aanvraag uit met onderstaande diensten.",
                    "multiple": true,
                    "answers": [
                        {
                            "name"      : "Verduurzamingsadvies",
                            "title"     : "Verduurzamingsadvies",
                            "content"   : "Wij beoordelen op welke onderdelen de woning energiezuiniger kan worden gemaakt.",
                            "icon"      : "zonnepanelen",
                            "conditions": {
                                "all": [
                                    {
                                        "name": "interests",
                                        "value": "Bouwkundige keuring"
                                    }
                                ],
                                "some": []
                            }
                        },
                        {
                            "name"      : "Verbouwadvies",
                            "title"     : "Verbouwadvies",
                            "content"   : "Wij kijken naar de technische haalbaarheid van uw plannen en maken een kostenraming.",
                            "icon"      : "advies",
                            "conditions": {
                                "all": [
                                    {
                                        "name": "interests",
                                        "value": "Bouwkundige keuring"
                                    }
                                ],
                                "some": []
                            }
                        },
                        {
                            "name"      : "Verduurzamingslabel",
                            "title"     : "Verduurzamingslabel",
                            "content"   : "U ontvangt een verduurzamingsrapport hoe het energielabel kan worden verbeterd.",
                            "icon"      : "energielabel",
                            "conditions": {
                                "all": [
                                    {
                                        "name": "interests",
                                        "value": "Energielabel"
                                    }
                                ],
                                "some": []
                            }
                        },
                    ]
                },





                {
                    "title": "Wat wilt u verduurzamen?",
                    "category": "sustainability",
                    "content": "Selecteer hieronder welke onderdelen u wilt verduurzamen.",
                    "multiple": true,
                    "answers": [
                        {
                            "name"      : "Zonnepanelen",
                            "title"     : "Zonnepanelen",
                            "icon"      : "zonnepanelen"
                        },
                        {
                            "name"      : "CV ketel",
                            "title"     : "CV-ketel",
                            "icon"      : "ketel"
                        },
                        {
                            "name"      : "Dakisolatie",
                            "title"     : "Dakisolatie",
                            "icon"      : "dakisolatie"
                        },
                        {
                            "name"      : "Vloerverwarming",
                            "title"     : "Vloerverwarming",
                            "icon"      : "vloerverwarming"
                        },
                        {
                            "name"      : "Vloerisolatie",
                            "title"     : "Vloerisolatie",
                            "icon"      : "vloerisolatie"
                        },
                        {
                            "name"      : "Spouwmuur isolatie",
                            "title"     : "Spouwmuur-isolatie",
                            "icon"      : "spouwmuur"
                        },
                        {
                            "name"      : "HR++ glas",
                            "title"     : "HR++ glas",
                            "icon"      : "glas"
                        },
                        {
                            "name"      : "Warmtepomp",
                            "title"     : "Warmtepomp",
                            "icon"      : "warmtepomp"
                        },
                        {
                            "name"      : "Overig",
                            "title"     : "Overig",
                            "icon"      : "overig"
                        }
                    ],
                    "conditions": {
                        "all": [
                            {
                                "name": "services",
                                "value": "Verduurzamingsadvies"
                            },
                        ],
                        "some": [
                        ]
                    },
                },


                {
                    "title": "Verbouwplannen",
                    "category": "renovationplans",
                    "content": "Vertel hieronder wat voor verbouwplannen u heeft.",
                    "multiple": false,
                    "hasTextArea": true,
                    "answers": [
                        {
                            "name" : "opmerking",
                            "title": "textarea",
                            "width": "span12"
                        }
                    ],
                    "conditions": {
                        "all": [
                            {
                                "name": "services",
                                "value": "Verbouwadvies"
                            }
                        ],
                        "some": [
                        ],
                    },
                },
                {
                    "title": "Uitvoering en budget",
                    "content": "Heeft u al nagedacht over de uitvoering en het budget? <br> Vul deze hieronder in.",
                    "multiple": false,
                    "hasTextInput": true,
                    "answers": [
                        {
                            "name" : "uitvoering",
                            "title": "Uitvoering",
                            "inputLabel": "Uitvoering",
                            "width": "span12"
                        },
                        {
                            "name" : "budget",
                            "title": "Budget",
                            "inputLabel": "Budget",
                            "width": "span12"
                        }
                    ],
                    "conditions": {
                        "all": [
                            {
                                "name": "services",
                                "value": "Verbouwadvies"
                            }
                        ],
                        "some": [],
                    },
                },
                {
                    "title": "Bouwbegeleiding",
                    "category": "construction",
                    "content": "Heeft u interesse in bouwbegeleiding?",
                    "multiple": false,
                    "answers": [
                        {
                            "name"      : "Wel bouwbegeleiding",
                            "title"     : "Wel bouwbegeleiding",
                            "content"   : "Laat u ontzorgen en schakel een projectmanager in om uw project te begeleiden.",
                            "icon"      : "bouwkundige_keuring"
                        },
                        {
                            "name"      : "Geen bouwbegeleiding",
                            "title"     : "Geen bouwbegeleiding",
                            "content"   : "Laat u NIET ontzorgen en schakel geen projectmanager in om uw project te begeleiden.",
                            "icon"      : "bouwbegeleiding"
                        }
                    ],
                    "conditions": {
                        "all": [
                            {
                                "name": "interests",
                                "value": "Bouwkundige keuring"
                            }
                        ],
                        "some": [],
                    },
                },
                {
                    "title": "Persoonlijke gegevens",
                    "category": "details",
                    "content": "Vul uw gegevens in zodat wij direct een offerte naar u kunnen sturen of contact met u kunnen opnemen.",
                    "multiple": false,
                    "hasTextInput": true,
                    "answers": [
                        {
                            "name" : "aanhef",
                            "title": "Aanhef",
                            "inputLabel": "Aanhef *",
                            "width": "span12",
                            "kind": "select",
                        },
                        {
                            "name" : "voornaam",
                            "title": "Voornaam",
                            "inputLabel": "Voornaam *",
                            "width": "span6"
                        },
                        {
                            "name" : "achternaam",
                            "title": "Achternaam",
                            "inputLabel": "Achternaam *",
                            "width": "span6"
                        },
                        {
                            "name" : "emailadres",
                            "title": "E-mailadres",
                            "inputLabel": "E-mail *",
                            "width": "span6"
                        },
                        {
                            "name" : "telefoonnummer",
                            "title": "Telefoonnummer",
                            "inputLabel": "Telefoonnummer *",
                            "width": "span6"
                        },
                        {
                            "name" : "opmerkingen",
                            "title": "Opmerking",
                            "inputLabel": "Opmerking",
                            "width": "span12",
                            "kind": "textarea"
                        }
                    ],
                    "conditions": {
                        "all": [],
                        "some": [],
                    },
                }
            ]
        });


        const conditional = (data) => {
            return computed(() => {
                //Ingevulde data gebruiken om "data" te filteren
                return data.filter(field => {
                    if (!field.conditions) return true;

                    if (field.conditions.all?.length) {
                        let valid = true;
                        field.conditions.all.forEach(condition => {
                            // const answers = [condition.category];
                            const answers = componentData.values[condition.name] ?? [];
                            if (!answers.includes(condition.value)) {
                                valid = false;
                            }
                        });
                        if (!valid) return false;
                    }
                    if (field.conditions.some?.length) {
                        let valid = false;
                        field.conditions.some.forEach(condition => {
                            const answers = componentData.values[condition.name] ?? [];
                            if (answers.includes(condition.value)) {
                                valid = true;
                            }
                        });
                        if (!valid) return false;
                    }
                    return true;
                });
            }).value;
        };



        provide("generalData", generalData);
        provide("componentData", componentData);
        provide("conditional", conditional);
    }
});
</script>

<style>

</style>